import { render, staticRenderFns } from "./pronto-intervento.html?vue&type=template&id=25c05da9&scoped=true&"
import script from "./pronto-intervento.js?vue&type=script&lang=js&"
export * from "./pronto-intervento.js?vue&type=script&lang=js&"
import style0 from "./pronto-intervento.scss?vue&type=style&index=0&id=25c05da9&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c05da9",
  null
  
)

export default component.exports