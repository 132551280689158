export default {
  name: 'card',
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: false,
    },
    button: {
      type: String,
      required: false,
    },
  },
  data() {
    return {

    };
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  },
};
