export default {
  name: 'navigation-bottom',
  components: {},
  props: {
    prev: {
      type: String,
      required: false,
    },
    prevText: {
      type: String,
      required: false,
    },
    next: {
      type: String,
      required: false,
    },
    nextText: {
      type: String,
      required: false,
    },
  },
};
