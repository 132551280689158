export default {
  name: 'button-call-now',
  props: {
    text: {
      type: String,
      required: false,
      default: 'Chiamaci subito',
    },
  },
  data() {
    return {
      telephone: process.env.VUE_APP_TELEPHONE,
    };
  },
};
