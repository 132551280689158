import ScrollMagic from 'scrollmagic';
import { TweenMax, TimelineMax } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

ScrollMagicPluginGsap(ScrollMagic, TweenMax);

export default {
  name: 'page-section',
  components: {},
  inject: ['isMobile'],
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const controller = new ScrollMagic.Controller();
    const timeline = new TimelineMax();
    timeline.fromTo(this.$el.querySelectorAll('h2, p'), {
      opacity: 0,
      x: -200,
    }, {
      x: 0,
      opacity: 1,
      ease: 'power1.inOut',
      stagger: {
        amount: 0.5,
      },
    });
    const scene = new ScrollMagic.Scene({
      triggerElement: this.$el,
      offset: -250,
      duration: this.isMobile() ? '50%' : '75%',
    })
      .setTween(timeline);

    controller.addScene(scene);
  },
  methods: {

  },
};
