import ScrollMagic from 'scrollmagic';
import ucfirst from 'lodash/startCase';
import { TweenMax, TimelineMax } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import PageSection from './page-section/index.vue';
import Meta from '@/classes/Meta';

ScrollMagicPluginGsap(ScrollMagic, TweenMax);

export default {
  name: 'smart-home',
  components: {
    PageSection,
  },
  inject: ['isMobile'],
  beforeMount() {
    Meta.setTitle('Smart Home');
  },
  mounted() {
    this.controller = new ScrollMagic.Controller();
    this.animate();
    this.animateIphones();
  },
  methods: {
    animate() {
      const timeline = new TimelineMax({
        duration: 1,
      });

      timeline.from('.fadeInLeft', {
        opacity: 0,
        x: -200,
        ease: 'power1.inOut',
        stagger: {
          amount: 0.2,
        },
      });

      timeline.from('fadeIn', {
        opacity: 0,
        ease: 'power1.inOut',
      });
    },

    animateIphones() {
      this.controller
        .addScene(this.sceneIntelligente())
        .addScene(this.sceneSemplice())
        .addScene(this.sceneSicura());
    },
    sceneIntelligente() {
      return this.addSceneAnimation('intelligente');
    },
    sceneSemplice() {
      return this.addSceneAnimation('semplice');
    },
    sceneSicura() {
      return this.addSceneAnimation('sicura');
    },
    addSceneAnimation(name) {
      const sectionName = `section${ucfirst(name)}`;
      const timeline = new TimelineMax();

      timeline.from(this.$refs[name], { opacity: 0, y: 200, ease: 'power1.inOut' });
      timeline.to(this.$refs[name], { opacity: 0 });

      return new ScrollMagic.Scene({
        triggerElement: this.$refs[sectionName],
        offset: 0,
        duration: '110%',
      })
        .setTween(timeline);
    },
  },
};
