import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/home/index.vue';
import ProntoIntervento from '@/views/pronto-intervento/index.vue';
import DoveSiamo from '@/views/dove-siamo/index.vue';
import Prodotti from '@/views/i-nostri-prodotti/index.vue';
import SmartHome from '@/views/smart-home/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/pronto-intervento',
    name: 'ProntoIntervento',
    component: ProntoIntervento,
  },
  {
    path: '/dove-siamo',
    name: 'DoveSiamo',
    component: DoveSiamo,
  },
  {
    path: '/i-nostri-prodotti',
    name: 'Prodotti',
    component: Prodotti,
  },
  {
    path: '/smart-home',
    name: 'Smart home',
    component: SmartHome,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
