import ProductsGallery from '@/components/products-gallery/index.vue';
import Meta from '@/classes/Meta';
import Animate from '@/classes/Animate';

export default {
  name: 'i-nostri-prodotti',
  components: {
    ProductsGallery,
  },
  beforeMount() {
    Meta.setTitle('I nostri prodotti');
  },
  mounted() {
    Animate.fadeInLeft(this.$el);
  },
};
