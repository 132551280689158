export default {
  name: 'page-default',
  components: {},
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    image: {
      type: String,
      required: false,
    },
    imageExtension: {
      type: String,
      required: false,
      default: 'png',
    },
    alt: {
      type: String,
      required: false,
    },
  },
  data() {
    return {

    };
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    /**
     * Get image url based on @x
     * @param {string} size
     */
    getImageUrl(size) {
      const sizeDuplicator = size === '1x' ? '' : `@${size}`;
      const path = this.imageExtension === 'png' ? `/img/${size}` : '/img';
      const image = this.imageExtension !== 'svg' ? `${this.image}${sizeDuplicator}` : this.image;
      return `${path}/${image}.${this.imageExtension}`;
    },
    getSrcSetImage() {
      return `${this.getImageUrl('1x')} 1x, ${this.getImageUrl('2x')} 2x, ${this.getImageUrl('3x')} 3x`;
    },
  },
};
