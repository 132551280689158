import Vue from 'vue';
import navigation from '@/components/navigation/index.vue';
import buttonCallNow from '@/components/button-call-now/index.vue';
import navigationBottom from '@/components/navigation-bottom/index.vue';
import page from '@/components/page/index.vue';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';

// import SmoothScroll from './classes/SmoothScroll';

Vue.config.productionTip = false;

Vue.component('navigation', navigation);
Vue.component('navigation-bottom', navigationBottom);
Vue.component('button-call-now', buttonCallNow);
Vue.component('page', page);

new Vue({
  router,
  render: (h) => h(App),
  provide() {
    return {
      isMobile: () => this.isMobile(),
    };
  },
  mounted() {
    // if (!this.isMobile()) {
    // eslint-disable-next-line no-unused-vars
    // const smooth = new SmoothScroll(document, 100, 12);
    // }
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
}).$mount('#app');
