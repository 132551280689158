export default {
  name: 'products-gallery',
  components: {},
  inject: ['isMobile'],
  data() {
    return {
      products: [
        {
          name: 'Tapparelle',
          image: '/img/products/tapparelle.jpg',
          orientation: 'landscape',
          description: 'Installazione e manutenzione',
        },
        {
          name: 'Zanzariere',
          image: '/img/products/zanzariere.jpg',
          orientation: 'portrait',
          description: 'Installazione e manutenzione',
        },
        {
          name: 'Serrature',
          image: '/img/products/serrature.jpg',
          orientation: 'portrait',
          description: 'Sostituzione da doppia mappa a cilindro europeo ',
        },
        {
          name: 'Cassaforti',
          image: '/img/products/casseforti.jpg',
          description: 'Installazione e manutenzione',
        },
        {
          name: 'Cancelli',
          image: '/img/products/cancelli.jpg',
          orientation: 'landscape',
          description: 'Realizzazione su misura<br>Verniciatura a polvere',
        },
        {
          name: 'Porte blindate',
          image: '/img/products/porte-blindate.jpg',
          orientation: 'portrait',
          description: 'Installazione e manutenzione',
        },
        {
          name: 'Recinzioni per scale e balconi',
          image: '/img/products/scale.jpg',
          orientation: 'portrait',
          description: 'Realizzazione su misura<br>Verniciatura a polvere',
        },
        {
          name: 'Inferriate apribili',
          image: '/img/products/inferriate-apribili.jpg',
          orientation: 'portrait',
          description: 'Realizzazione su misura<br>Verniciatura a polvere',
        },
        {
          name: 'Inferriate fisse',
          image: '/img/products/inferriate-fisse.jpg',
          orientation: 'portrait',
          description: 'Realizzazione su misura<br>Verniciatura a polvere',
        },
        {
          name: 'Serrande',
          description: 'Manutenzione e installazione Serranda',
          orientation: 'portrait',
          image: '/img/products/serranda.jpg',
        },
      ],
      productSelected: null,
    };
  },
  mounted() {
    // eslint-disable-next-line prefer-destructuring
    this.productSelected = this.products[0];
  },
  methods: {
    selectProduct(p) {
      if (this.isMobile()) {
        this.$refs.imageMobile[0].style.height = '0px';
      }

      this.productSelected = null;

      this.$nextTick(() => {
        this.productSelected = p;
      });
    },
    animateHeightMobile() {
      if (this.isMobile()) {
        setTimeout(() => {
          const image = this.$refs.imageMobile[0].querySelector('img');
          const p = this.$refs.imageMobile[0].querySelector('p');
          const style = image.parentNode.currentStyle || window.getComputedStyle(image.parentNode);
          const styleP = p.currentStyle || window.getComputedStyle(p);
          // eslint-disable-next-line radix
          let height = image.clientHeight + (parseInt(style.marginTop) * 2);
          // eslint-disable-next-line radix
          height += parseInt(styleP.marginTop) + p.clientHeight;
          this.$refs.imageMobile[0].style.height = `${height}px`;
        }, 400);
      }
    },
  },
};
