export default {
  name: 'review',
  components: {},
  props: {
    value: {
      required: true,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.review = val;
        }
      },
    },
  },
  data() {
    return {
      review: null,
    };
  },
};
