import ScrollMagic from 'scrollmagic';
import { TweenMax, TimelineMax } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

ScrollMagicPluginGsap(ScrollMagic, TweenMax);

export default {
  name: 'message',
  inject: ['isMobile'],
  props: {
    left: {
      type: Boolean,
      required: false,
      default: false,
    },
    right: {
      type: Boolean,
      required: false,
      defualt: false,
    },
    image: {
      type: String,
      required: false,
      default: '',
    },
  },
  mounted() {
    const controller = new ScrollMagic.Controller();

    const timeline = new TimelineMax();
    timeline.add(TweenMax.fromTo(this.$refs.baloon, ...this.animateMessage(-200)));

    const timeline2 = new TimelineMax();
    timeline2.add(TweenMax.fromTo(this.$refs.photo, {
      opacity: 0,
      y: 100,
    }, {
      y: 0,
      opacity: 1,
      ease: 'power1.inOut',
      stagger: {
        amount: 0.2,
      },
    }));

    const el = '.page-col';

    const scene = new ScrollMagic.Scene({
      triggerElement: el,
    })
      .setTween(timeline);

    const scene2 = new ScrollMagic.Scene({
      triggerElement: el,
    })
      .setTween(timeline2);

    controller.addScene(scene).addScene(scene2);
  },
  methods: {
    /**
     * Animate baloon message
     * @param {JSON} x
     */
    animateMessage(x) {
      return [{
        opacity: 0,
        x(e, el) {
          if (el.parentNode.classList.contains('message--left')) {
            return x;
          }
          return -x;
        },
      }, {
        x: 0,
        opacity: 1,
        ease: 'power1.inOut',
        stagger: {
          amount: 1,
        },
      }];
    },
  },
};
