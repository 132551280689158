export default {
  name: 'navigation',
  components: {},
  props: [],
  data() {
    return {
      navOpened: false,
    };
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    toggleNav() {
      this.navOpened = !this.navOpened;
    },
  },
};
