export default [{
  text: 'Da Anni collaboriamo con la società Daci fornendogli tutto ciò di cui ha bisogno. Abbiamo condiviso con lui molti dei nostri clienti più fidati perché sappiamo che sà garantire un lavoro onesto ed altamente professionale.',
  name: 'Ferramenta Scarazzini',
  time: '02 Febbraio 2020',
  stars: [0, 1, 2, 3, 4],
  photo: 'face1',
}, {
  text: 'Ero rimasto fuori casa a tarda notte sotto lo pioggia. Ho chiamato la società Daci che si è subito recata presso la mia abitazione. Il personale ha saputo sbloccarmi la porta di casa e metterla in sicurezza per la notte. La mattina seguente me l’avevano già rimpiazzata!',
  name: 'Marco Gallia',
  time: '23 Gennaio 2020',
  stars: [0, 1, 2, 3],
  photo: 'face2',
}, {
  text: 'Siamo da sempre fornitori della ditta DACI. Alta professionalità nel lavoro e ottima qualità manifatturiera. Realizza su misura ogni genere di serramento (cancellate, recinzioni, inferiate e molto altro). Saremmo felici di raccomandarlo per qualsiasi lavoro. Non esiste fabbro migliore nella provincia di Alessandria!',
  name: 'DINO FER S.r.l.',
  time: '14 Gennaio 2020',
  stars: [0, 1, 2, 3],
  photo: 'face3',
}];
