import Meta from '@/classes/Meta';
import Card from '@/components/card/index.vue';
import Review from '@/components/review/index.vue';
import reviews from '@/views/home/assets/reviews';
import Animate from '@/classes/Animate';

export default {
  name: 'home',
  components: {
    Card,
    Review,
  },
  inject: ['isMobile'],
  data() {
    return {
      reviews,
    };
  },
  beforeMount() {
    Meta.setTitle('');
  },
  mounted() {
    Animate.fadeInLeft(this.$refs.page1.$el);
    Animate.fadeInUp(this.$refs.page1.$el);

    Animate.scrollMagic(this.$refs.page2.$el, '.review', {
      method: 'from',
      scene: { offset: -300, duration: 0 },
      params: [{ opacity: 0, y: 200, stagger: { amount: 0.5 } }],
    });

    Animate.scrollMagic(this.$refs.page2.$el, 'h1', {
      method: 'fromTo',
      scene: { offset: -300, duration: null },
      params: [{ opacity: 0, x: -200 }, { opacity: 1, x: 0 }],
    });
  },
};
