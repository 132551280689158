import ScrollMagic from 'scrollmagic';
import { TweenMax, TimelineMax } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import Meta from '@/classes/Meta';

ScrollMagicPluginGsap(ScrollMagic, TweenMax);

export default {
  name: 'dove-siamo',
  beforeMount() {
    Meta.setTitle('Dove siamo');
  },
  data() {
    return {
      telephone: process.env.VUE_APP_TELEPHONE,
      email: process.env.VUE_APP_EMAIL,
    };
  },
  mounted() {
    this.animate();
  },
  methods: {
    animate() {
      const timeline = new TimelineMax({
        duration: 1,
      });

      timeline.from('.fadeInLeft', {
        opacity: 0,
        x: -200,
        ease: 'power1.inOut',
        stagger: {
          amount: 0.2,
        },
      });

      timeline.from('fadeIn', {
        opacity: 0,
        ease: 'power1.inOut',
      });
    },
  },
};
