import { TimelineMax, TweenMax } from 'gsap';
import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

ScrollMagicPluginGsap(ScrollMagic, TweenMax);

const controller = new ScrollMagic.Controller();

class Animate {
  static fadeInLeft(el) {
    const timeline = new TimelineMax({
      duration: 1,
    });
    timeline.from(el.querySelectorAll('.fadeInLeft'), {
      opacity: 0,
      x: -200,
      ease: 'power1.inOut',
      stagger: {
        amount: 0.2,
      },
    });
  }

  static fadeInUp(el) {
    const timeline = new TimelineMax({
      duration: 1,
    });
    timeline.from(el.querySelectorAll('.fadeInUp'), {
      opacity: 0,
      y: 200,
      ease: 'power1.inOut',
      stagger: {
        amount: 0.2,
      },
    });
  }

  /**
   *
   * @param {DOMNode} parent
   * @param {DOMNode} child
   */
  static scrollMagic(parent, child, options) {
    const timeline = new TimelineMax();

    timeline.add(TweenMax[options.method](parent.querySelectorAll(child), ...options.params));

    const sceneOptions = {
      triggerElement: parent,
      offset: 200,
      duration: '100%',
      ...options.scene,
    };

    const scene = new ScrollMagic.Scene(sceneOptions)
      .setTween(timeline);

    controller.addScene(scene);
  }
}

export default Animate;
