import ScrollMagic from 'scrollmagic';
import { TweenMax, TimelineMax } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import Message from '@/components/message/index.vue';
import Meta from '@/classes/Meta';

ScrollMagicPluginGsap(ScrollMagic, TweenMax);

export default {
  name: 'pronto-intervento',
  components: {
    Message,
  },
  inject: ['isMobile'],
  data() {
    return {
      telephone: process.env.VUE_APP_TELEPHONE,
    };
  },
  beforeMount() {
    Meta.setTitle('Pronto intervento');
  },
  mounted() {
    this.controller = new ScrollMagic.Controller();

    this.animateFirstSection();

    const timeline = new TimelineMax();

    timeline.add(TweenMax.fromTo('#page-2-h2', {
      opacity: 0,
      x: -200,
    }, {
      x: 0,
      opacity: 1,
      ease: 'power1.inOut',
    }));

    const timeline2 = new TimelineMax();
    timeline2.add(TweenMax.fromTo('.page-2-p', {
      opacity: 0,
      x: -200,
    }, {
      x: 0,
      opacity: 1,
      ease: 'power1.inOut',
      stagger: {
        amount: 0.2,
      },
    }));

    const scene = new ScrollMagic.Scene({
      triggerElement: '#page-2',
      offset: this.isMobile() ? 200 : 700,
      duration: '100%',
    })
      .setTween(timeline);

    const scene2 = new ScrollMagic.Scene({
      triggerElement: '#page-2',
      duration: this.isMobile() ? '50%' : '100%',
      offset: this.isMobile() ? -200 : 0,
    })
      .setTween(timeline2);

    this.controller
      .addScene(scene)
      .addScene(scene2);
  },
  methods: {
    animateFirstSection() {
      const timeline = new TimelineMax({
        duration: 1,
      });
      timeline.from('.fadeInLeft', {
        opacity: 0,
        x: -200,
        ease: 'power1.inOut',
        stagger: {
          amount: 0.2,
        },
      });
    },
  },
};
